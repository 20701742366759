/**
 * @description action
 * @author liyang
 */
import key from './key'
import { Resource } from '@ekuaibao/fetch'
import { QuerySelect } from 'ekbc-query-builder'
// const rules = new Resource('/api/v1/rule')
const invoiceAction = new Resource('/api/v2/invoice')
const generateConfig = new Resource('/api/v2/withNotes/generate')
const autoSubmitConfig = new Resource('/api/v2/withNotes/submit')
const withNotesGroupConfig = new Resource('/api/withNotesGroupConfig/v2')
const withNotesGroupingConfig = new Resource('/api/withNotesGroupingConfig/v2')
const specificationGroups = new Resource('/api/form/v2/specificationGroups')
const dataLinkAction = new Resource('/api/v2/datalink')
export function getPlanConfigs() {
  return {
    type: key.GET_PLAN_CONFIG_LIST,
    payload: invoiceAction.GET('/usageNorm')
  }
}

//获取费用生成设置列表
export function getAllGenerateConfig() {
  const params = {
    limit: {
      start: 0, //起始页
      count: 999 //每页总数
    }
  }
  return {
    type: key.GET_ALL_GENERATE_CONFIG,
    payload: generateConfig.POST('/config/search', params)
  }
}

//根据ID获取费用生成配置
export function getGenerateConfigById(id) {
  return {
    type: key.GET_GENERATE_CONFIG_BYID,
    payload: generateConfig.GET('/config/${id}', id)
  }
}

//新增费用生成配置
export function addGenerateConfig(param) {
  return {
    type: key.ADD_GENERATE_CONFIG,
    payload: generateConfig.POST('/config', param)
  }
}

//修改费用生成配置
export function modifyGenerateConfig(param) {
  return {
    type: key.MODIFY_GENERATE_CONFIG,
    payload: generateConfig.PUT('/config', param)
  }
}

//获取自动提报设置列表
export function getAutoSubmitConfig() {
  const params = {
    limit: {
      start: 0, //起始页
      count: 999 //每页总数
    }
  }
  return {
    type: key.GET_AUTO_SUBMIT_CONFIG,
    payload: autoSubmitConfig.POST('/config/search', params)
  }
}

//新增自动提报配置
export function addAutoSubmitConfig(param) {
  return {
    type: key.ADD_AUTO_SUBMIT_CONFIG,
    payload: autoSubmitConfig.POST('/config', param)
  }
}

//修改自动提报配置
export function modifyAutoSubmitConfig(param) {
  return {
    type: key.MODIFY_AUTO_SUBMIT_CONFIG,
    payload: autoSubmitConfig.PUT('/config', param)
  }
}

// 保存消费事项配置
export function setWithNotesGroupConfig(params) {
  return {
    type: key.SET_WITH_NOTES_GROUP_CONFIG,
    payload: withNotesGroupConfig.POST('', { ...params })
  }
}

// 查询消费事项配置列表
export function getWithNotesGroupConfigList() {
  const start = 0
  const count = 1000
  const query = new QuerySelect().orderBy('createTime', 'DESC').limit(start, count).value()
  return {
    type: key.GET_WITH_NOTES_GROUP_CONFIG_LIST,
    payload: withNotesGroupConfig.POST('/search', { ...query })
  }
}

// 更新接口
export function updateWithNotesGroupConfig(id, params) {
  return {
    type: key.UPDATE_WITH_NOTES_GROUP_CONFIG,
    payload: withNotesGroupConfig.PUT('/$id', { id, ...params })
  }
}

// 归集规则保存接口
export function SaveWithNotesGroupingConfig(params) {
  return {
    type: key.SAVE_WITH_NOTES_GROUPING_CONFIG,
    payload: withNotesGroupingConfig.POST('', { ...params })
  }
}

// 更新归集规则
export function UpdateWithNotesGroupingConfig(id, params) {
  return {
    type: key.UPDATE_WITH_NOTES_GROUPING_CONFIG,
    payload: withNotesGroupingConfig.PUT('/$id', { id, ...params })
  }
}

// 获取归集配置详情
export function GetWithNotesGroupingConfig(id) {
  const start = 0
  const count = 1000
  const query = new QuerySelect().orderBy('createTime', 'DESC').limit(start, count).value()
  return {
    type: key.SEARCH_WITH_NOTES_GROUPING_CONFIG,
    payload: withNotesGroupingConfig.POST('/search', { ...query })
  }
}

// 消费事项停启用
export function EnableWithNotesGroupConfig(id, enable) {
  return {
    type: key.ENABLE_WITH_NOTES_GROUP_CONFIG,
    payload: withNotesGroupConfig.PUT('/enable/$id/$enable', null, { id, enable })
  }
}

// 消费事项停启用
export function EnableWithNotesCollectionConfig(id, enable) {
  return {
    type: key.ENABLE_WITH_NOTES_COLLECTION_CONFIG,
    payload: withNotesGroupingConfig.PUT('/enable/$id/$enable', null, { id, enable })
  }
}

// 删除消费事项
export function DeleteWithNotesExpenseConfig(id) {
  return {
    type: key.DELETE_WITH_NOTES_EXPENSE_CONFIG,
    payload: withNotesGroupConfig.DELETE('/$id', { id })
  }
}

// 删除归集规则
export function DeleteWithNotesCollectionConfig(id) {
  return {
    type: key.DELETE_WITH_NOTES_COLLECTION_CONFIG,
    payload: withNotesGroupingConfig.DELETE('/$id', { id })
  }
}

export function deleteGenerateConfig(id) {
  return {
    type: key.DELETE_GENERATE_CONFIG,
    payload: generateConfig.DELETE('/config/$id', { id })
  }
}

export function deleteAutoSubmitConfig(id) {
  return {
    type: key.DELETE_AUTO_SUBMIT_CONFIG,
    payload: autoSubmitConfig.DELETE('/config/$id', { id })
  }
}

export function getBillTemplate(types) {
  return {
    type: key.GET_BILL_TEMPLATE,
    payload: specificationGroups.GET('/versionedByTypes', { types })
  }
}

export function getEntityList() {
  let join = {
    join: `platformId,platformId,/v2/datalink/platform`,
    join$1: 'parentId,parentId,/v2/datalink/entity'
  }
  return {
    type: key.GET_DATALINK_ENTITY,
    payload: dataLinkAction.GET('/entity/autoExpense/getEntityList', { ...join })
  }
}
