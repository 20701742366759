/**
 * @description 常量key 集合
 * @author liyang
 */
export const ID = `@auto_expense`
export default {
  ID: ID,
  GET_PLAN_CONFIG_LIST: `${ID}/GET_PLAN_CONFIG_LIST`,
  SET_WITH_NOTES_GROUP_CONFIG: `${ID}/SET_WITH_NOTES_GROUP_CONFIG`,
  GET_WITH_NOTES_GROUP_CONFIG_LIST: `${ID}/GET_WITH_NOTES_GROUP_CONFIG_LIST`,
  GET_ALL_GENERATE_CONFIG: `${ID}/GET_ALL_GENERATE_CONFIG`,
  GET_GENERATE_CONFIG_BYID: `${ID}/GET_GENERATE_CONFIG_BYID`,
  ADD_GENERATE_CONFIG: `${ID}/ADD_GENERATE_CONFIG`,
  MODIFY_GENERATE_CONFIG: `${ID}/MODIFY_GENERATE_CONFIG`,
  GET_AUTO_SUBMIT_CONFIG: `${ID}/GET_AUTO_SUBMIT_CONFIG`,
  ADD_AUTO_SUBMIT_CONFIG: `${ID}/ADD_AUTO_SUBMIT_CONFIG`,
  MODIFY_AUTO_SUBMIT_CONFIG: `${ID}/MODIFY_AUTO_SUBMIT_CONFIG`,
  UPDATE_WITH_NOTES_GROUP_CONFIG: `${ID}/UPDATE_WITH_NOTES_GROUP_CONFIG`,
  SAVE_WITH_NOTES_GROUPING_CONFIG: `${ID}/SAVE_WITH_NOTES_GROUPING_CONFIG`,
  UPDATE_WITH_NOTES_GROUPING_CONFIG: `${ID}/UPDATE_WITH_NOTES_GROUPING_CONFIG`,
  GET_WITH_NOTES_GROUPING_CONFIG: `${ID}/GET_WITH_NOTES_GROUPING_CONFIG`,
  SEARCH_WITH_NOTES_GROUPING_CONFIG: `${ID}/SEARCH_WITH_NOTES_GROUPING_CONFIG`,
  ENABLE_WITH_NOTES_GROUP_CONFIG: `${ID}/ENABLE_WITH_NOTES_GROUP_CONFIG`,
  ENABLE_WITH_NOTES_COLLECTION_CONFIG: `${ID}/ENABLE_WITH_NOTES_COLLECTION_CONFIG`,
  DELETE_WITH_NOTES_EXPENSE_CONFIG: `${ID}/DELETE_WITH_NOTES_EXPENSE_CONFIG`,
  DELETE_WITH_NOTES_COLLECTION_CONFIG: `${ID}/DELETE_WITH_NOTES_COLLECTION_CONFIG`,
  DELETE_GENERATE_CONFIG: `${ID}/DELETE_GENERATE_CONFIG`,
  DELETE_AUTO_SUBMIT_CONFIG: `${ID}/DELETE_AUTO_SUBMIT_CONFIG`,
  GET_BILL_TEMPLATE: `${ID}/GET_BILL_TEMPLATE`,
  GET_DATALINK_ENTITY: `${ID}/GET_DATALINK_ENTITY`
}
