/**
 * @description 消费事项配置规则
 * @author liyang
 */
import { app } from '@ekuaibao/whispered'
import key from './key'
import { getEntityList } from './auto-expense-action'

export default [
  {
    id: key.ID,
    // path: '/auto-expense',
    ref: '/',
    reducer: () => import('./auto-expense-reducer'),
    // onload: () => import('./auto_expense_view'),
    'get:datalink:entity'() {
      return app.dispatch(getEntityList())
    }
  },
  {
    path: '/auto-expense',
    ref: '/',
    onload: () => import('./auto-expense/auto-expense-view')
  },
  {
    path: '/auto-submit',
    ref: '/',
    onload: () => import('./autoSubmit/auto-submit-view')
  },
  {
    path: '/cost-generation',
    ref: '/',
    onload: () => import('./costGeneration/cost-generation-view')
  },
  {
    point: '@@layers',
    prefix: '@auto-expense',
    onload: () => import('./layers')
  },
  {
    point: '@@components',
    namespace: key.ID,
    onload: () => [{ key: 'AutoExpenseCollectionForm', component: () => import('./layers/AutoExpenseCollectionForm') }]
  },
]
